import { Vue, Component, Prop } from "vue-property-decorator";

import { WarningType, WarningGroupedByType } from "@/types/warning";
import { warningModule } from "@store/namespaces";
import { WarningGetters, WarningActions } from "@store/modules/warning/types";

import Warning from "./components/Warning/Warning.vue";

@Component({
  components: {
    Warning,
  },
})
export default class Warnings extends Vue {
  @warningModule.Getter("warningsGroupByTypes")
  private readonly warningsGroupByTypesGetter!: WarningGetters["warningsGroupByTypes"];

  @warningModule.Action("hideWarnings")
  private readonly hideWarningsAction!: WarningActions["hideWarnings"];

  @Prop({ type: Array }) private readonly includeTypes?: WarningType[];

  private activeWarningIndex = 0;
  private alertTransitionName: "alerts-slide-down" | "alerts-slide-up" =
    "alerts-slide-up";

  private get warningsList() {
    return this.warningsGroupByTypesGetter(this.includeTypes);
  }

  private get lastWarningIndex() {
    return this.warningsList.length - 1;
  }

  private get activeWarning() {
    return this.warningsList[this.activeWarningIndex];
  }

  private get showedWarnings() {
    return !!this.activeWarning;
  }

  private get countWarnings() {
    return this.warningsList.length;
  }

  private get activeWarningNumber() {
    return this.activeWarningIndex + 1;
  }

  private goToWarning(alertIndex: number) {
    if (alertIndex < 0) {
      this.activeWarningIndex = this.lastWarningIndex;
    } else if (alertIndex > this.lastWarningIndex) {
      this.activeWarningIndex = 0;
    } else {
      this.activeWarningIndex = alertIndex;
    }
  }

  private nextWarning() {
    this.goToWarning(this.activeWarningIndex + 1);
    this.alertTransitionName = "alerts-slide-down";
  }

  private prevWarning() {
    this.goToWarning(this.activeWarningIndex - 1);
    this.alertTransitionName = "alerts-slide-up";
  }

  private onKeyDown(event: KeyboardEvent) {
    switch (event.code) {
      case "ArrowRight":
      case "ArrowDown":
        event.preventDefault();
        this.nextWarning();
        break;

      case "ArrowLeft":
      case "ArrowUp":
        event.preventDefault();
        this.prevWarning();
        break;

      default:
        break;
    }
  }

  private onClickAlerts() {
    const $el = this.$el as HTMLDivElement;

    $el.focus();
  }

  private hideWarnings({ ids }: WarningGroupedByType) {
    this.hideWarningsAction({ ids });
  }

  private mounted() {
    this.$watch(
      () => {
        return this.lastWarningIndex;
      },
      (lastWarningIndex) => {
        this.activeWarningIndex = Math.max(
          Math.min(this.activeWarningIndex, lastWarningIndex),
          0
        );
      },
      {
        immediate: true,
      }
    );
  }
}
