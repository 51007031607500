import { Vue, Component, Prop } from "vue-property-decorator";
import { WarningGroupedByType, WarningType } from "@/types/warning";

@Component
export default class Warning extends Vue {
  @Prop({ type: Object, required: true })
  private readonly data!: WarningGroupedByType;

  private get warningLink() {
    return {
      name: "cards-with-limit",
      query: {
        cardIds: this.data.params.cardIds.join(","),
      },
    };
  }

  private get messageAlert() {
    switch (this.data.type) {
      case WarningType.CARD_SUSPENDED:
        return this.$vuetify.lang.t(
          "$vuetify.warnings.cards_suspended_update_status"
        );

      default:
        return "";
    }
  }

  private onHide() {
    this.$emit("hide", this.data);
  }
}
